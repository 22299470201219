// packages
import * as React from "react";
import { graphql } from "gatsby";
// components
import MainLayout from "../components/MainLayout";
import Seo from "../components/Seo";
import {
  LeadingParagraph,
  MainHeading,
  SubHeading,
  StyledParagraph,
  ParagraphContainer
} from "../components/Typography";

const DesignerInsights = ({ data }) => (
  <MainLayout>
    <Seo title="designer insights" />
    <MainHeading>Designer Insights</MainHeading>
    <SubHeading>Prompt</SubHeading>
    <ParagraphContainer>
      <StyledParagraph>{data.victoryJson.prompt}</StyledParagraph>
    </ParagraphContainer>
    <SubHeading>Jon</SubHeading>
    <ParagraphContainer>
      <LeadingParagraph>{data.victoryJson.leadingParagraph}</LeadingParagraph>
      <StyledParagraph>{data.victoryJson.paragraphTwo}</StyledParagraph>
    </ParagraphContainer>
    <SubHeading>Lukas</SubHeading>
    <ParagraphContainer>
      <LeadingParagraph>
        Donec enim diam vulputate ut pharetra sit amet aliquam. Dictum non consectetur a erat.
        Bibendum neque egestas congue quisque egestas. Felis bibendum ut tristique et egestas. Leo
        duis ut diam quam nulla porttitor massa id. Risus ultricies tristique nulla aliquet enim
        tortor. Urna molestie at elementum eu facilisis sed odio morbi. Ut tristique et egestas
        quis. Felis donec et odio pellentesque diam. Ultrices in iaculis nunc sed augue lacus
        viverra. Adipiscing at in tellus integer feugiat. Ut tristique et egestas quis ipsum
        suspendisse. Sit amet volutpat consequat mauris. Ultrices neque ornare aenean euismod
        elementum nisi quis eleifend quam. Malesuada fames ac turpis egestas integer eget aliquet.
        Faucibus turpis in eu mi bibendum neque egestas congue. Ipsum dolor sit amet consectetur
        adipiscing elit duis tristique sollicitudin.
      </LeadingParagraph>
      <StyledParagraph>
        Ac turpis egestas sed tempus urna et pharetra. Scelerisque mauris pellentesque pulvinar
        pellentesque. Felis imperdiet proin fermentum leo vel. Risus feugiat in ante metus dictum at
        tempor commodo ullamcorper. Duis at consectetur lorem donec massa sapien faucibus. Sagittis
        eu volutpat odio facilisis. Id diam vel quam elementum pulvinar. In egestas erat imperdiet
        sed euismod nisi porta lorem. Tincidunt arcu non sodales neque sodales ut etiam. In hac
        habitasse platea dictumst vestibulum rhoncus est pellentesque. Non blandit massa enim nec
        dui nunc mattis enim ut. Vitae semper quis lectus nulla at. Turpis egestas sed tempus urna
        et pharetra pharetra. Metus dictum at tempor commodo. Enim eu turpis egestas pretium aenean
        pharetra magna. Ornare quam viverra orci sagittis eu. Neque egestas congue quisque egestas
        diam. Platea dictumst quisque sagittis purus sit. Bibendum neque egestas congue quisque
        egestas diam in.
      </StyledParagraph>
    </ParagraphContainer>
  </MainLayout>
);

export const query = graphql`
  query {
    victoryJson {
      prompt
      leadingParagraph
      paragraphTwo
    }
  }
`;

export default DesignerInsights;
